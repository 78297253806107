export default {
  layouts: {
    sidebar: {
      personalManager: '個人管理',
      serviceManager: '運用管理'
    }
  },
  views: {
    common: {
      totalParticipantsNum: '{{num}}人',
      numLuggage: '{{num}}個',
      serviceDay: '乗車日',
      station: '列車',
      luggage: '手荷物',
      confirmBooking: '内容確認',
      checkContent: '上記内容で問題ない',
      channel: '購入区分',
      selected: '選択中',
      reset: '選択席リセット'
    },
    login: {
      title: 'Welcome to Sagano Station',
      loginStation: 'ログイン場所'
    },
    dashboard: {
      title: 'ダッシュボード'
    },
    searchInventories: {
      title: '空席照会/新規予約',
      usingGroupArrangementType: '団体枠利用',
      freeBooking: '無賃予約',
      fromStation: '乗車駅',
      toStation: '降車駅',
      unitQuantityItems: '乗車人員',
      luggage: '手荷物',
      note: 'メモ',
      fromToStationIsSame: '乗車駅と降車駅が同じ',
      wheelChairs: '車椅子スペース利用台数',
      wheelChairsSpaceNum: '{{num}}席',
      unitQuantityExceeded: '乗車人員は最大50人です',
      luggageCountExceeded: '手荷物は最大99個です',
      byDate: {
        title: '列車照会',
        filters: '現在設定している条件',
        wheelChairsSpace: '車椅子スペース',
        seatsNum: '席{{num}}',
        standingNum: '立{{num}}',
        wheelChairNum: '車{{num}}',
        serviceInfos:
          '({{fromStation}}駅 {{departureHhmm}}発 ～ {{toStation}}駅 {{arrivalHhmm}}着）',
        expiredTips: '発時刻を確認ください。'
      },
      byServiceId: {
        title: '空席確認',
        toRelease: '団体枠開放へ',
        tips: '利用する席を選択してください。現在選択中の席には<0></0>がつきます。'
      }
    },
    releaseGroupArrangement: {
      title: '団体枠開放',
      tips: 'HP/OTA枠に解放する団体席を選択してください。現在選択中の席には<0></0>がつきます。',
      next: '開放と内容確認'
    },
    irregularBooking: {
      title: 'イレギュラー登録'
    },
    luggage: {
      title: '手荷物登録'
    },
    luggageConfirm: {
      title: '手荷物 内容確認'
    },
    searchBookings: {
      title: '個人予約一覧/検索',
      bookingStatus: '予約ステータス',
      paymentStatus: '決済ステータス',
      issuedStatus: '発券ステータス',
      serviceStatus: '運行ステータス',
      printStatus: '発券ステータス',
      orderType: '予約タイプ'
    },
    booking: {
      scheduledPayment: 'お支払い額',
      changePaymentFrom: '変更前お支払額',
      changePaymentTo: '変更後お支払額',
      changePaymentDecrease: '徴収額',
      seat: '座席',
      couponType: '券種',
      paymentStatus: '決済ステータス',
      couponCode: '発券番号',
      applyDate: '申し込み日',
      payment: '決済',
      source: '購入区分',
      handleFee: '手数料徴収あり',
      cancelHandleFee: 'キャンセル手数料の設定',
      commission: '手数料の設定',
      breakdown: '内訳',
      others: 'その他',
      fee: '料金',
      continueBooking: '続けて登録',
      goToPay: '決済確認',
      supporterOver: '介護者の人数が障害者第１種の人数を超えています',
      wheelChairsOver: '車椅子数が合計人数を超えています'
    },
    inventoryArrangements: {
      vacantNumber: '空席数',
      reserved: '予約済',
      updateSuccess: '枠調整を更新しました。'
    },
    bookingDetail: {
      title: '予約詳細',
      handleBtn: '予約キャンセル',
      updateNumber: '便の変更回数：',
      updateNumberUnit: '{{number}} 回目'
    },
    bookingHistory: {
      title: '予約受付内容'
    },
    bookingUpdate: {
      title: '予約の変更',
      handleBtn: '予約を変更する',
      changeNum: '現在の変更回数： {{number}} 回',
      updateUnitTips: '変更前と人数一致すること',
      updateDecreaseTips: '減少のみ',
      updateButton: '変更内容確認',
      updateHandleFee: '手数料 {{price}}円 × {{quantity}}'
    },
    bookingCancel: {
      title: '予約キャンセル',
      handleBtn: 'キャンセルを確定する',
      tips: '下記の予約を解約（払戻）しますか？',
      cancelFree: '払戻手数料額',
      cancelFreeDetail: '{{unit}}手数料 {{price}}円 × {{quantity}}名',
      cancelLuaggageFreeDetail: '{{unit}}手数料 {{price}}円 × {{quantity}}個'
    },
    bookingConfirm: {
      rangeInfo:
        '{{fromStation}}（{{departureHhmm}}）〜 {{toStation}}（{{arrivalHhmm}}）',
      bookingIsFinished: '登録が完了しました',
      wheelChairNumber: '{{car}} × {{quantity}}台'
    },
    bookingPayment: {
      title: '決済確認',
      payTips: '決済処理を行ってください。',
      printReceiptTips: '発券処理を行ってください。',
      confirmTitle: 'スマレジで決済してください。',
      confirmContent: 'スマレジ画面を確認し、決済手続きを完了してください。',
      done: '決済完了',
      printReceipt: '発券',
      confirmCancelTitle:
        '登録情報を破棄します。破棄した予約は元に戻せません。',
      confirmCancelContent: 'よろしいですか？',
      printReceiptSuccess: '発券が完了しました。',
      printReceiptFailed: '発券が失敗しました、プリンターの接続を確認ください。'
    },
    button: {
      next: '次へ'
    },
    checkbox: {
      custom: 'お客様都合'
    },
    bookingChannel: {
      BOOKING_CHANNEL_WEB: 'web',
      BOOKING_CHANNEL_SAGA: '嵯峨',
      BOOKING_CHANNEL_ARASHIYAMA: '嵐山',
      BOOKING_CHANNEL_KAMEOKA: '亀岡',
      BOOKING_CHANNEL_SAGA_COLLECTED: '嵯峨集',
      BOOKING_CHANNEL_ARASHIYAMA_COLLECTED: '嵐山集',
      BOOKING_CHANNEL_KAMEOKA_COLLECTED: '亀岡集',
      BOOKING_CHANNEL_CONDUCTOR: '車掌',
      BOOKING_CHANNEL_CONDUCTOR_COLLECTED: '車掌集'
    },
    paymentStatus: {
      INITED: '未確定',
      INVOICE_PENDING: '請求書未発行',
      PAYMENT_PENDING: '未払',
      PAYMENT_CONFIRMED: '支払済',
      COUPON_CONFIRMED: '船車券支払済',
      PAYMENT_CANCELD: '支払キャンセル',
      NO_NEED_TO_PAY: '支払不要'
    },
    printStatus: {
      PRINT_STATUS_UNPRINTED: '未発券',
      PRINT_STATUS_PRINTED: '発券済'
    },
    orderType: {
      ORDER_TYPE_NOT_ONLY_LUGGAGE: '個人予約',
      ORDER_TYPE_LUGGAGE: '手荷物予約'
    },
    bookingStatus: {
      SUBMITTED: '予約受付',
      MODIFICATION_SUBMITTED: '予約変更受付',
      CANCELLATION_SUBMITTED: 'キャンセル受付',
      RESERVED: '予約済み',
      NOT_RESERVED: '予約NG',
      MODIFIED: '予約変更済み',
      NOT_MODIFIED: '予約変更NG',
      CANCELLED: 'キャンセル',
      CANCELLATION_PENDING: 'キャンセル処理中',
      WAITLIST: 'キャンセル待ち'
    },
    issuedStatusTypeId: {
      ISSUED_STATUS_UNISSUED: '発券未',
      ISSUED_STATUS_ISSUED: '発券済'
    },
    serviceStatusTypeId: {
      SERVICE_STATUS_ORDINARY: '通常',
      SERVICE_STATUS_SPECIAL: '定期外',
      SERVICE_STATUS_CHARTER: '貸切',
      SERVICE_STATUS_SUSPENDED: '運休',
      SERVICE_STATUS_PENDING: '発売保留'
    },
    bookingParamsType: {
      CREATE: '新規予約',
      SERVICE_UPDATE: '列車変更',
      TRAVELLER_UPDATE: '人数変更（減）',
      UNIT_UPDATE: '券種変更',
      LUGGAGE_UPDATE: '手荷物変更（減）',
      CANCEL: 'キャンセル',
      SEAT_UPDATE: '座席変更',
      SECTION_UPDATE: '区間変更'
    },
    redemptionStatus: {
      UNUSED: '未利用',
      USED: '利用済',
      EXPRIED: '期限切れ'
    },
    qrSearch: {
      title: 'QR/番号検索',
      subTitle: 'レシートのQRコードを読み取るか、予約番号で検索してください。',
      error: '予約がみつかりませんでした。'
    },
    report: {
      title: '帳票データ出力',
      type: 'データ出力',
      date: '期間',
      downloadSuccess: '帳票データ出力が完了しました。',
      reportTypes: {
        booking: '予約データ',
        train: '列車データ',
        suspended: '運休対象予約'
      },
      reportTips: '{{name}}ありませんでした！'
    }
  },
  dateFormats: {
    default: 'MM月dd日'
  }
};
